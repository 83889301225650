import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Tabs,
  IconWrench,
  IconInk,
  IconCalendarWrench,
  IconCalendarInk,
} from "@veneer/core";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BulkNavigation = (props) => {
  let query = useQuery();
  const mfeType = {
    0: "supplies",
    1: "supplyHistory",
    2: "service",
    3: "serviceHistory",
  };
  const contractData = props.data.contractInfo;
  const type = props?.type;
  const history = useHistory();
  const buttonClick = localStorage.getItem("buttonClick");
  const tabType = query.get("type")
  let tabToHighlight = 0;

  if ((tabType === 'supplies' || tabType === 'trust') && buttonClick === 'supplies') {
    tabToHighlight = 0;
  } else if (tabType === 'supplyHistory') {
    tabToHighlight = 1;
  } else if (((tabType === 'service' || tabType === 'trust') && buttonClick === 'service') || tabType === 'service') {
    tabToHighlight = 2;
  } else if (tabType === 'serviceHistory') {
    tabToHighlight = 3
  }


  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(parseInt(tabToHighlight));
  const handleTabChange = (tabIndex) => {
    setSelectedTabIdDefault(tabIndex);
    loadMFE(mfeType[tabIndex]);
  };
  const loadMFE = (mfeComponentType) => {
    const url = `/trustOrderDetail?id=${contractData.masterAgreementId}&type=${mfeComponentType}`
    history.push(url);
  };

  const serviceTabList = [

    {
      id: 0,
      enable: true,
      tabIndex:'supplies',
      label: (
        <div
          style={{
            width: "50px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            contentAlign: "center",
            justifyContent: "center",
            padding: "0px 0px 0px 0px",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          <div
            style={{
              zIndex: "15",
              marginBottom: "8px",
              position: "relative",
              top: "9px",
            }}
          >
            <IconInk filled = {selectedTabIdDefault === 0} size={23} style={{ color: "#027aae" }} />
          </div>
          <div>Supply <br/> Order</div>
        </div>
      ),
    },
   {
      id: 1,
      enable: true,
      tabName: "supplyHistory",
      label: (
        <div
          style={{
            width: "50px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            contentAlign: "center",
            justifyContent: "center",
            padding: "0px 0px 0px 0px",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          <div
            style={{
              zIndex: "15",
              marginBottom: "8px",
              position: "relative",
              top: "9px",
            }}
          >
            <IconCalendarInk filled = {selectedTabIdDefault === 1} size={23} style={{ color: "#027aae" }} />
          </div>
          <div>Supply <br/> Order History</div>
        </div>
      ),
    },
    {
      id: 2,
      enable: true,
      tabName: 'services',
      label: (
        <div
          style={{
            width: "50px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            contentAlign: "center",
            justifyContent: "center",
            padding: "0px !important",
            fontSize: "14px",
            lineHeight: "16px",
            marginTop: "6px",
          }}
        >
          <div style={{ zIndex: "15" }}>
            <IconWrench filled = {selectedTabIdDefault === 2} size={22} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            Service <br></br> Request
          </div>
        </div>
      ),
    },
   {
      id: 3,
      enable: true,
      tabName:  'serviceHistory',
      label: (
        <div
          style={{
            width: "50px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            contentAlign: "center",
            justifyContent: "center",
            padding: "0px 0px 0px 0px",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          <div
            style={{
              zIndex: "15",
              marginBottom: "8px",
              position: "relative",
              top: "9px",
            }}
          >
            <IconCalendarWrench filled = {selectedTabIdDefault === 3} size={23} style={{ color: "#027aae" }} />
          </div>
          <div>Service <br/> History</div>
        </div>
      ),
    }
  ];


  const mfetabList = serviceTabList
  const enableTabslist = mfetabList.filter ((tab) => {
    return tab.enable === true;
  })




  return (
    <div id="navContainerWrapper">
      <div className="navContainer">
        <Tabs
          mode="extended"
          onChangeTab={handleTabChange}
          selectedTabId={selectedTabIdDefault}
          tabs={enableTabslist}
          type="online"
        />
      </div>
    </div>
  );
};


export default BulkNavigation;
