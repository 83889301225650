import { Card, IconCheckCircle, IconXCircle } from "@veneer/core";
import React from "react";
import moment from "moment";


function TrustInfoCard(props) {
    const contractData = props.data.contractInfo;
    let trustStartMonth = "00";
    let trustStartDate = "00";
    let trustStartYear = "00";
    trustStartMonth = moment.utc(contractData.contractStartDate, "YYYYMMDD").toDate().getUTCMonth() + 1;
    trustStartDate = moment.utc(contractData.contractStartDate, "YYYYMMDD").toDate().getUTCDate();
    trustStartYear = moment.utc(contractData.contractStartDate, "YYYYMMDD").toDate().getFullYear();
    return (
        <Card
            appearance="dropShadow"
            style={{
                width: "375px",
                padding: "20px 24px 24px 24px",
                margin: "24px",
            }}
            content={

                <div>
                     <div className="subtitle-regular cardTitle">
                        <span className="itsmK2 caption"> {contractData.sourceSystem}</span>

                        <span style={{ position: "relative", top: "2px" }}>
                            Contract Details
                        </span>

                    </div>
                    <hr className="hr"></hr>
                    <div className="modelName">
                        <h6
                            style={{
                                fontSize: "18px",
                                lineHeight: "16px",
                                textAlign: "center",
                                margin: "20px 0 18px 0",
                            }}
                        >
                            {contractData.customerName}
                        </h6>
                    </div>

                    <div className="cardContainerSpaceAround">
                        <div className="StatusActiveOpenEntitled-Container">
                            <label
                                className="StatusActiveOpenEntitled-Label label-small overline-regular"
                                style={{ fontSize: "12px" }}
                            >
                                Trusted
                            </label>
                            {contractData.trusted && (
                                <label>
                                    <IconCheckCircle
                                        filled
                                        className="StatusActiveOpenEntitled-Check"
                                        size={18}
                                    />
                                </label>
                            )}
                        </div>
                        {contractData && contractData.bulkOrderEnabled &&
                        <div className="StatusActiveOpenEntitled-Container">
                            <label
                                className="StatusActiveOpenEntitled-Label label-small overline-regular"
                                style={{ fontSize: "12px" }}
                            >
                                Entitled
                            </label>
                            {(contractData.bulkOrderEnabled || contractData.trusted) && (
                                <label>
                                    <IconCheckCircle
                                        filled
                                        className="StatusActiveOpenEntitled-Check"
                                        size={18}
                                    />
                                </label>
                            )}
                            {(!contractData.bulkOrderEnabled || !contractData.trusted) && (
                                <label>
                                    <IconXCircle
                                        filled
                                        className="StatusActiveOpenEntitled-CrossCheck"
                                        size={18}
                                    />
                                </label>
                            )}
                        </div>}
                    </div>
                    <hr></hr>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                        }}
                    >
                        <div className="smallCardLabel smallCardLabel-Condensed label">
                           Contract Number:
                            <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                                {contractData.contractNo}
                            </label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                        }}
                    >
                        <div className="smallCardLabel smallCardLabel-Condensed label">
                           Billing Model:
                            <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                                {contractData.billingModel}
                            </label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                        }}
                    >
                        <div className="smallCardLabel smallCardLabel-Condensed label">
                           Contract Type:
                            <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                                {contractData.contractType}
                            </label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px",
                        }}
                    >
                        <div className="smallCardLabel smallCardLabel-Condensed label">
                           Start Date:
                            <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                            mm: {trustStartMonth}  / dd: {trustStartDate} / yy: {trustStartYear}
                            </label>
                        </div>
                    </div>

                </div>
            }
        />

    );
}

export default TrustInfoCard;