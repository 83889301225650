import { IconCheckCircle, IconClock, IconMinusCircle, IconPlusCircle } from "@veneer/core";
import React, { useEffect, useState } from "react";
import moment from "moment";

function TrustSpecialHandlingCard(props) {
    const contractData = props.data.contractInfo;
    const [showComments, setshowComments] = useState(true);
    const [isHideIcon, setisHideIcon] = useState(false);
     const openCommentBox = () => {
        setshowComments(true);
        setisHideIcon(false);
    };
    const closeCommentBox = () => {
        setshowComments(false);
        setisHideIcon(true);
    };
    return (
        <div
            style={{
                zIndex: "9",
                display: "flex",
                position: "relative",
                left: "405px",
                top: "93px",
            }}
        >
            <div
                style={{
                    width: "1244px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "30px",
                    position: "fixed",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{ width: "743px", display: "flex", flexDirection: "column" }}
                >
                    <div
                        className="smallGroupedItemsContainer-Row"
                        style={{ gap: "44px", marginTop: "-10px", marginLeft: "-10px" }}
                    >
                        <div className="dateCreatedDisplayDateContainer-Condensed-Column">

                        </div>
                        <div>


                            {contractData?.comments && (
                                <div
                                    style={{
                                        width: "400px",
                                        height: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingBottom: "24px",
                                        backgroundColor: "#f8f8f8",
                                        position: "relative",
                                        left: "311px",
                                        top: "20px",
                                    }}
                                >
                                    <div className="StatusActiveOpenEntitled-Container">
                                        <div style={{ padding: "0 8px 0 0px" }}></div>
                                        <div className="StatusActiveOpenEntitled-Label overline-regular">
                                            Special Handling Instructions
                                        </div>
                                        <div>
                                            <IconCheckCircle
                                                filled
                                                className="StatusActiveOpenEntitled-Check"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                height: "24px",
                                                position: "relative",
                                                top: "0px",
                                                left: "113px",
                                                backgroundColor: "#ffffff",
                                                borderRadius: "24px",
                                            }}
                                        >
                                            {isHideIcon && (
                                                <IconPlusCircle
                                                    style={{ cursor: "pointer", color: "#027aae" }}
                                                    size={24}
                                                    onClick={openCommentBox}
                                                />
                                            )}

                                            {!isHideIcon && (
                                                <IconMinusCircle
                                                    style={{ cursor: "pointer", color: "#027aae" }}
                                                    size={24}
                                                    onClick={closeCommentBox}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {showComments && (
                                        <p
                                            className="commentLabel label"
                                            style={{
                                                marginTop: "10px",
                                                marginLeft: "2px",
                                                backgroundColor: "#f8f8f8",
                                                padding: "8px 16px",
                                                boxShadow: "0px 0px 13px rgba(0,0,0,0.2)",
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <span className="commentLabelText">{contractData?.comments && contractData?.comments}</span>
                                        </p>
                                    )}
                                </div>
                            )}
                            {/* COMMENTS -WITHOUT COMMENTS */}
                            {!contractData?.comments && (
                                <div
                                    style={{
                                        width: "400px",
                                        height: "20px",
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingBottom: "24px",
                                        backgroundColor: "#f8f8f8",
                                        position: "relative",
                                        left: "244px",
                                        top: "20px",
                                    }}
                                >
                                    <div
                                        className="StatusActiveOpenEntitled-Container"
                                        style={{ backgroundColor: "#d3d3d380" }}
                                    >
                                        <div style={{ padding: "0 8px 0 0px" }}></div>
                                        <div className="StatusActiveOpenEntitled-Label overline-regular">
                                            Special Handling Instructions
                                        </div>
                                        <div>
                                            <IconCheckCircle filled className="StatusCLOSED-X" />
                                        </div>
                                        <div
                                            style={{
                                                height: "24px",
                                                position: "relative",
                                                top: "-1px",
                                                left: "125px",
                                                backgroundColor: "#ffffff",
                                                borderRadius: "24px",
                                            }}
                                        >
                                            <IconPlusCircle
                                                disabled
                                                style={{ cursor: "pointer", color: "#027aae" }}
                                                size={24}
                                            />
                                        </div>
                                    </div>
                                    <p
                                        className="commentLabel label"
                                        style={{
                                            textAlign: "center",
                                            marginTop: "0px",
                                            padding: "8px 0px",
                                        }}
                                    >
                                        There are no instructions to show at this time
                                    </p>
                                </div>
                            )}


                        </div>

                    </div>


                </div>

            </div>
        </div>

    );
}

export default TrustSpecialHandlingCard
