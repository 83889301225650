import React, {useContext, useEffect, useState} from "react";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import sessionContext from "../contexts/sessionContext";
import jwt_decode from "jwt-decode";
import { sendGaEvent } from "../helper/analytics";

let countdownInterval;
let timeout;

export default function SessionTimeout(props) {

  const handleLogOutClick = useContext(sessionContext).handleLogOutClick;
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };
  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };
  const handleLogout = (isTimedOut = false) => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    handleLogOutClick();
    sendGaEvent('session_logout', 'user', 'User session time logout');
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    let expiryTime = parseInt(localStorage.getItem('expiryTime'));
    let currentTime = (new Date().getTime()) / 1000;
    let logOutTime = expiryTime - currentTime;
    setTimeout(() => {
      clearSessionInterval();
      clearSessionTimeout();
      handleLogOutClick();
    }, logOutTime * 1000);
    sendGaEvent('session_continue', 'user', 'User session time continued');
  };


  const setSessionTimeout = (expiryTime, currentTime) => {
    const tokenExpiryWarningTime = expiryTime - 300;
    let tokenExpiryRemainingTime = tokenExpiryWarningTime - currentTime;
    tokenExpiryRemainingTime = tokenExpiryRemainingTime > 0 ? tokenExpiryRemainingTime : 1;
    localStorage.setItem('tokenExpiryRemainingTime', (tokenExpiryRemainingTime * 1000).toString());
    timeout = setTimeout(() => {
      let countDown = 5;
      setTimeoutModalOpen(true);
      setTimeoutCountdown(countDown);
    }, parseInt(localStorage.getItem('tokenExpiryRemainingTime')));

  };
  useEffect(() => {

    let mounted = true;
    let expiryTime = localStorage.getItem('expiryTime');
    if (mounted) {
      if (expiryTime === null) {
        if (window._env_["REACT_APP_ENVIRONMENT"] === 'LOCAL') {
          localStorage.setItem('expiryTime', ((new Date().getTime() / 1000 + 28800)).toString());
        } else {

          let jwtToken = localStorage.getItem('token');
          let decodedJwtToken = jwt_decode(jwtToken);
          localStorage.setItem('expiryTime', decodedJwtToken['exp']);

        }
      }
      let currentTime = (new Date().getTime()) / 1000;
      expiryTime = parseInt(localStorage.getItem('expiryTime'));
      setSessionTimeout(expiryTime, currentTime);
      const logOutTime = expiryTime - currentTime;
      if ((logOutTime) < 0) {
        clearSessionInterval();
        clearSessionTimeout();
        handleLogOutClick();
        sendGaEvent('session_logout', 'user', 'User session time logout');
      }
    }

    return () => mounted = false;
  }, [])


  return (
    <>
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      />
    </>
  );
}
