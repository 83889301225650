import React from "react";

const AdditionalDeviceCardInfo = (props) => {
  const data = props?.data;

    return (
      <div>
            <div
                style={{
                    display: "inline-block",
                    flexDirection: "row",
                    margin: "0px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    MS Contract ID:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details[0]?.ms_contract_id}
                        {data.source_system?.value === "ITSM" &&
                            data.device_detail?.ms_contract_id}
                    </label>
                </div>
            </div>

        <hr className="hr"></hr>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    B/F Service Level:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details &&
                            data.extended_details[0]?.break_fix_service_level}
                        {data.source_system?.value === "ITSM" &&
                            data.break_fix_service_level}
                    </label>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    KIT Service Level:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details &&
                            data.extended_details[0]?.kits_service_level}
                        {data.source_system?.value === "ITSM" &&
                            data.kits_service_level}
                    </label>
                </div>
            </div>

        <hr className="hr"></hr>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Billing Model:
                    <label className="smallCardLabelData smallCardLabel-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details.billing_model}
                        {data.source_system?.value === "ITSM" &&
                            data.provider_contracts[0]?.products[0]
                                ?.sub_product_characteristics[0]?.value}
                    </label>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Business Model:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details &&
                            data.extended_details[0]?.business_model}
                        {data.source_system?.value === "ITSM" &&
                            data.provider_contracts[0]?.master_agreement?.master_agreement_extensions?.deal_type}
                    </label>
                </div>
            </div>

        <hr className="hr"></hr>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Sales Method:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.sales_method}
                    </label>
                </div>
            </div>

            <div
                style={{
                    display: "inline-block",
                    flexDirection: "row",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Contract ID:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "ITSM" &&
                            data.device_detail?.contract_id}
                    </label>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Solutions ID:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.source_system?.value === "K2" &&
                            data.extended_details &&
                            data.extended_details[0]?.solution_id}
                        {data.source_system?.value === "ITSM" &&
                            data.provider_contracts[0]?.products[0]?.custom_id}
                    </label>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "5px 0 3px",
                }}
            >
                <div className="smallCardLabel smallCardLabel-Condensed label">
                    Device Warranty:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.warrenty_details &&
                            data.warrenty_details[0]?.warrenty_type_code}
                    </label>
                </div>
            </div>


      </div>


    );

};

export default AdditionalDeviceCardInfo;