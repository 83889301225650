import { Button, Card, IconCheckCircle, IconInk, IconShoppingCart, IconWarning, IconWrench, IconXCircle } from "@veneer/core";
import React from "react";

const ContractCard = (props) => {
    const searchType = props?.searchType;
    const data = props?.data;
    const isBulkOrderEnabled = window._env_["REACT_APP_ENABLE_BULK_ORDER_MFE_398"];
    const isTrustOrderEnabled = window._env_["REACT_APP_ENABLE_TRUST_ORDER_MFE_195"];
    const redirectToNewTab = (id, type, component, btnEvent) => {
        let url;
        localStorage.setItem("buttonClick", btnEvent);
        if (type === 'bulk' && component === 'bulkOrderDetail') {
            url = `/bulkOrderDetail?id=${id}&type=${type}`
        }
        if ((type === 'trust' || type === 'bulk') && component === 'trustOrderDetail') {
            url = `/trustOrderDetail?id=${id}&type=${type}`
        }
        window.open(url);
    };
    return (
        <Card
            appearance="dropShadow" style={{ width: "300px", padding: "20px 24px 24px 24px", marginRight: "20px", }}
            content={
                <>
                    <div>
                        <div className="subtitle-regular" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="itsmContract caption">{data.sourceSystem}</div>
                            <div style={{ position: "relative", top: "1px", left: "-72px" }}>Trust Details</div>
                            {/* <div style={{marginTop:"8px"}}><BtnIconShareURLTopTip style={{cursor:"pointer"}} /></div> */}
                        </div>
                        <hr className="hr" style={{marginTop: "10px"}}></hr>
                        {/* <h6 style={{ fontSize: "18px", lineHeight: "18px", textAlign: "center", margin: "30px 0 0px 0" }}>DSS / VAS Team Device</h6> */}
                        <div className="custemerNameAlignment">
                            <h6 style={{ fontSize: "18px", lineHeight: "18px", textAlign: "center", margin: "16px 0 30px 0", wordBreak: "normal" }}>{data.customerName}</h6>
                        </div>
                        <div className="cardContainerSpaceAround" style={{ marginTop: "-10px", marginBottom: "30px" }}>
                            {searchType === "Bulk" && <div className="StatusActiveOpenEntitled-Container">
                                <div className="StatusActiveOpenEntitled-Label label-small overline-regular" style={{ fontSize: "12px" }}>
                                    Entitled
                                </div>
                                <div>

                                    {data.trusted && data.bulkOrderEnabled && <IconCheckCircle filled className="StatusActiveOpenEntitled-Check" size={15} />}
                                    {(!data.bulkOrderEnabled || !data.trusted) && <IconXCircle filled className="StatusActiveOpenEntitled-CrossCheck" size={15} />}
                                </div>
                            </div>}
                            {searchType === "Trust" && <div className="StatusActiveOpenEntitled-Container">
                                <div className="StatusActiveOpenEntitled-Label label-small overline-regular" style={{ fontSize: "12px" }}>
                                    Entitled
                                </div>
                                <div>
                                    {data.trusted && <IconCheckCircle filled className="StatusActiveOpenEntitled-Check" size={15} />}
                                    {!data.trusted && <IconXCircle filled className="StatusActiveOpenEntitled-CrossCheck" size={15} />}
                                </div>
                            </div>}

                            <div className="StatusActiveOpenEntitled-Container">
                                <div className="StatusActiveOpenEntitled-Label label-small overline-regular" style={{ fontSize: "12px" }}>
                                    Trusted
                                </div>
                                    {data.trusted && <IconCheckCircle filled className="StatusActiveOpenEntitled-Check" size={15} />}
                                    {!data.trusted && <IconXCircle filled className="StatusActiveOpenEntitled-CrossCheck" size={15} />}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", marginTop: "18px" }}>
                            <div className="smallCardLabel smallCardLabel-Condensed label">Contract: </div>
                            <div className="smallCardLabelData smallCardLabelData-Condensed label"><b>{data.contractNo}</b></div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                            <div className="smallCardLabel smallCardLabel-Condensed label">Opportunity Id: </div>
                            <div className="smallCardLabelData smallCardLabelData-Condensed label"><b>{data.opportunityId}</b></div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                            <div className="smallCardLabel smallCardLabel-Condensed label">Country: </div>
                            <div className="smallCardLabelData smallCardLabelData-Condensed label"><b>{data.countryName}</b></div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="smallCardLabel smallCardLabel-Condensed label">Contract Type: </div>
                            <label className="smallCardLabelData smallCardLabelData-Condensed label"><b>{data.contractType}</b></label>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="smallCardLabel smallCardLabel-Condensed label">Billing Model:</div>
                            <label className="smallCardLabelData smallCardLabelData-Condensed label"><b>{data.billingModel}</b></label>
                        </div>

                        <hr className="hr"></hr>
                        <div style={{ width: "244px", padding: "6px 2px 24px 24px", marginRight: "20px", marginTop: "20px", }}>

                            <div className="smallCardLabel smallCardLabel-Condensed label"
                                style={{ textAlign: "center", marginRight: "20px", marginBottom: "10px" }}>
                                <IconShoppingCart />
                            </div>
                            <div className="smallCardLabel smallCardLabel-Condensed label"
                                style={{ textAlign: "center", marginRight: "20px", marginBottom: "10px", }}>
                                {searchType === "Bulk" ? "Place Bulk Order:" : "Place Trust Order:"}
                            </div>
                            <hr style={{ maginTop: "20px" }} className="hr"></hr>

                            {(searchType === "Bulk") && data.trusted && data.bulkOrderEnabled
                                && (isBulkOrderEnabled && isBulkOrderEnabled === "true") &&
                                <Button
                                    style={{ marginLeft: "75px", marginRight: "30px", marginTop: "27px" }}
                                    onClick={() => { redirectToNewTab(data.masterAgreementId, searchType.toLowerCase(), "bulkOrderDetail", "supply"); }}
                                    title="Supply Order"
                                    leadingIcon={<IconInk />}>
                                </Button>}
                        
                            {(searchType === "Bulk") && (isBulkOrderEnabled && isBulkOrderEnabled === "true") &&
                            (!data.trusted || !data.bulkOrderEnabled) &&
                            <div className="StatusActiveOpenEntitled-Container StatusActiveOpenEntitled-Label--specific overline-regular">
                                { (!data.trusted || !data.bulkOrderEnabled) && <b>  Customer not entitled, trust ordering not enabled </b>}
                            </div>
                            }

                            { searchType === "Trust" && data.trusted &&
                            (isTrustOrderEnabled && isTrustOrderEnabled === "true") &&
                                <Button
                                    style={{ marginLeft: "30px", marginRight: "30px", marginTop: "27px" }}
                                    onClick={() => {redirectToNewTab(data.masterAgreementId, searchType.toLowerCase(), "trustOrderDetail", "supply");}}
                                    title="Supply Order"
                                    leadingIcon={<IconInk />}>
                                </Button> }

                                {(searchType === "Trust") && !data.trusted
                                && (isTrustOrderEnabled && isTrustOrderEnabled === "true") &&
                                <div className="StatusActiveOpenEntitled-Container StatusActiveOpenEntitled-Label--specific overline-regular">
                                    { (!data.trusted) && <b>  Customer not entitled, trust ordering not enabled </b>}
                                </div>
                            }

                            {searchType === "Trust" && data.trusted && (isTrustOrderEnabled && isTrustOrderEnabled === "true") &&
                                <Button
                                    style={{ marginRight: "20px", marginTop: "27px" }}
                                    title="Service Request"
                                    onClick={() => { redirectToNewTab(data.masterAgreementId, searchType.toLowerCase(), "trustOrderDetail", "service"); }}
                                    leadingIcon={<IconWrench />}>
                                </Button> }


                        </div>

                    </div>
                </>}
            hoverable
        />
    );
}

export default ContractCard;