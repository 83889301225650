//import modules
import React, {Component} from 'react';
import {createTheme , MuiThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import Broadcast from './pages/broadcast'
import BroadcastHistory from './pages/broadcastHistory'
import DeviceDetailsComponent from "./pages/deviceDetails";
import CommonProvider from "./contexts/common";
import DevicesProvider from "./contexts/devices";
import RouteWrapper from "./layouts/RouteWrapper";
import CommonLayout from "./layouts/CommonLayout";
import "./assets/scss/style.scss";
import BulkOrderDetail from './pages/bulkOrder/bulkOrderDetail';
import TrustOrderDetail from './pages/trustOrder/trustOrderDetail';


const theme1 = createTheme ({
  palette: {
    primary: {
      main: "#0171ad",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
  },
  typography: {
    fontFamily: `HP Simplified`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)'
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.5em',
          heigth: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 2px rgba(0,0,0,20%)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#b9b8bb',
          outline: '1px solid #f2f2f2'

}
      }
    }
  },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
})
const isBroadcastMFE = window._env_["REACT_APP_ENABLE_BROADCAST_MFE"] == "true" || window._env_["REACT_APP_ENABLE_BROADCAST_MFE"]? true : false ;

class App extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme1}>
          <CommonProvider>
            <DevicesProvider>
              <Router>
                <div>
                  <Switch>
                    <Route exact path="/" component={LoginPage}/>
                    <Route exact path="/login" component={LoginPage} />
                    <RouteWrapper path="/search" component={HomePage} layout={CommonLayout}/>
                    {isBroadcastMFE? <RouteWrapper path="/broadcast" component={Broadcast} layout={CommonLayout}/> :""}
                    {isBroadcastMFE? <RouteWrapper path="/broadcastHistory" component={BroadcastHistory} layout={CommonLayout}/>:""}
                    <RouteWrapper path="/deviceDetails" component={DeviceDetailsComponent} layout={CommonLayout}/>
                    <RouteWrapper path="/bulkOrderDetail" component={BulkOrderDetail} layout={CommonLayout}/>
                    <RouteWrapper path="/trustOrderDetail" component={TrustOrderDetail} layout={CommonLayout}/>
                    <Route path="/*" render={(props) => <LoginPage {...props}/>}/>
                  </Switch>
                </div>
              </Router>
            </DevicesProvider>
          </CommonProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
