import React, { useReducer, createContext } from "react";
import axios from "axios";

const initialState = {
  devices: null,
  isLoading: false,
  isLoaded: false,
  searchKeyword: '',
  totalCount: null,
  region: 'EMEA'
};

export const DevicesStateContext = createContext();
export const DevicesDispatchContext = createContext();

const API_URL =  window._env_["REACT_APP_DEVICE_SERVICE_API"]

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_DEVICES_REQUEST":
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case "GET_DEVICES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isLoaded: action.payload.totalCount && action.payload.totalCount.aggregate &&
                  action.payload.totalCount.aggregate.count > 0 ? true : false,
        devices: action.payload.devices,
        totalCount: action.payload.totalCount,
        region: action.payload.region,
        searchType: action.payload.searchType,
      };
    case "GET_DEVICES_FAILURE":
      return {
        ...state,
        devices: [],
        isLoading: false,
        isLoaded: true,
        totalCount: null,
        region: localStorage.getItem('region')
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

const DevicesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DevicesDispatchContext.Provider value={dispatch}>
      <DevicesStateContext.Provider value={state}>
        {children}
      </DevicesStateContext.Provider>
    </DevicesDispatchContext.Provider>
  );
};

export const getDevices = (dispatch, searchKeyword, region, searchType) => {
  dispatch({
    type: "GET_DEVICES_REQUEST"
  });
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + ' ' + localStorage.getItem('token')
    }
  };
  const record = {
    searchText : searchKeyword.trim(),
    currentPage: 0,
    pagesize: 4,
    region: region === "" ? ["AMS","EMEA","APJ"] : [region],
    searchType
  }
  axios
    .post(API_URL + '/list', record, requestOptions)
    .then((response) => {
      dispatch({
        type: "GET_DEVICES_SUCCESS",
        payload: {
          devices: searchType === "Device" ? response.data.devices : response.data.contracts,
          totalCount: response.data.totalCount,
          region: response.data.region,
          searchType,
        }
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: "GET_DEVICES_FAILURE"
      });
    });
};

export default DevicesProvider;
