import axios from "axios";
import xss from 'xss';

const API_URL =  window._env_["REACT_APP_DEVICE_SERVICE_API"];
const DEFAULT_ASSET_NAME = 'DSS';

export async function getDeviceList (searchText, currentPage ='', pagesize = '', token, searchType) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + ' ' + localStorage.getItem('token')
    }
  };
  try {
    const record = {
      searchText : searchText,
      currentPage: currentPage,
      pagesize: pagesize,
      searchType
    }
    const response = await axios.post(API_URL + '/list', record, requestOptions);
    return response.data;

  } catch (error) {
    console.log(error);
   return {
     result: []
   }
  }
  }

export async function getDeviceDetails (deviceId, asset) {
  try {
    const assetName = asset ? asset: DEFAULT_ASSET_NAME;
    const sanitizedDeviceId = xss(deviceId)
    let requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
        'token': localStorage.getItem('token'),
        //'asset': assetName
      }
    };
    requestOptions = JSON.parse(xss(JSON.stringify(requestOptions)))
    const response = await axios.get(API_URL + `/details/${sanitizedDeviceId}`, requestOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      result: []
    }
  }
}

export async function getContractInfo (masterAgreementId, assetName) {
  try {
     let requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + localStorage.getItem('token'),
        'token': localStorage.getItem('token'),
      }
    };
    const sanitizedMasterAgreementId = xss(masterAgreementId);
    const sanitizedAssetName = xss(assetName);
    requestOptions = JSON.parse(xss(JSON.stringify(requestOptions)))
    const response = await axios.get(API_URL + `/contractInfo/${sanitizedMasterAgreementId}/${sanitizedAssetName}`, requestOptions);
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      result: []
    }
  }
}
