import moment from "moment";
import React, { useState } from "react";
import {
  IconPlusCircle,
  IconMinusCircle,
  IconCheckCircle,
  IconClock,
} from "@veneer/core";

const EntitleBar = (props) => {
  const data = props?.data;
  let serviceStartMonth = "00";
  let serviceStartDate = "00";
  let serviceStartYear = "00";
  let serviceEndMonth = "00";
  let serviceEndDate = "00";
  let serviceEndYear = "00";
  const [showComments, setshowComments] = useState(true);
  const [isHideIcon, setisHideIcon] = useState(false);
  const openCommentBox = () => {
    setshowComments(true);
    setisHideIcon(false);
  };
  const closeCommentBox = () => {
    setshowComments(false);
    setisHideIcon(true);
  };

  if(data.source_system?.value === "K2" && data.extended_details[0]?.device_start_date) {
    serviceStartMonth = moment.utc(data.extended_details[0]?.device_start_date, "YYYYMMDD").toDate().getUTCMonth() + 1;
    serviceStartDate = moment.utc(data.extended_details[0]?.device_start_date, "YYYYMMDD").toDate().getUTCDate();
    serviceStartYear = moment.utc(data.extended_details[0]?.device_start_date, "YYYYMMDD").toDate().getFullYear();
  } else if(data.provider_contracts[0]?.valid_from) {
    serviceStartMonth = moment.utc(data.provider_contracts[0]?.valid_from, "YYYYMMDD").toDate().getUTCMonth() + 1;
    serviceStartDate = moment.utc(data.provider_contracts[0]?.valid_from, "YYYYMMDD").toDate().getUTCDate();
    serviceStartYear = moment.utc(data.provider_contracts[0]?.valid_from, "YYYYMMDD").toDate().getFullYear();
  }
  if(data.source_system?.value === "K2" && data.extended_details[0]?.device_end_date) {
    serviceEndMonth = moment.utc(data.extended_details[0]?.device_end_date, "YYYYMMDD").toDate().getUTCMonth() + 1;
    serviceEndDate = moment.utc(data.extended_details[0]?.device_end_date, "YYYYMMDD").toDate().getUTCDate();
    serviceEndYear = moment.utc(data.extended_details[0]?.device_end_date, "YYYYMMDD").toDate().getUTCFullYear();
  } else if(data.provider_contracts[0]?.valid_to) {
    serviceEndMonth = moment.utc(data.provider_contracts[0]?.valid_to, "YYYYMMDD").toDate().getUTCMonth() + 1;
    serviceEndDate = moment.utc(data.provider_contracts[0]?.valid_to, "YYYYMMDD").toDate().getUTCDate();
    serviceEndYear = moment.utc(data.provider_contracts[0]?.valid_to, "YYYYMMDD").toDate().getUTCFullYear();
  }

  return (
    <div
      style={{
        zIndex: "9",
        display: "flex",
        position: "relative",
        top: "95px",
        left: "1020px",
      }}
    >
      <div
        style={{
          width: "1244px",
          display: "flex",
          flexDirection: "column",
          marginBottom: "30px",
          position: "fixed",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ width: "510px", display: "flex", flexDirection: "column" }}
        >
          <div
            className="smallGroupedItemsContainer-Row"
            style={{ gap: "44px", marginTop: "-10px", marginLeft: "-10px" }}
          >
            <div className="dateCreatedDisplayDateContainer-Condensed-Column">
              <div
                className="displayDateInstallService-Condensed label"
                style={{ marginTop: "-3px", backgroundColor: "#027AAE1A" }}
              >
                <IconClock style={{ marginTop: "-4px", marginRight: "8px" }} />
                <p style={{ marginTop: "-3px" }}>
                  mm: {serviceStartMonth} / dd: {serviceStartDate} / yy:{" "}
                  {serviceStartYear}
                </p>
              </div>
              <div className="dateLabelStackedwIcon label">
                SERVICE START DATE
              </div>
            </div>

            <div
              className="dateCreatedDisplayDateContainer-Condensed-Column"
              style={{ position: "relative", left: "-30px" }}
            >
              <div
                className="displayDateInstallService-Condensed label"
                style={{ marginTop: "-3px", backgroundColor: "#027AAE1A" }}
              >
                <IconClock style={{ marginTop: "-4px", marginRight: "8px" }} />
                <p style={{ marginTop: "-3px" }}>
                  {" "}
                  mm: {serviceEndMonth} / dd: {serviceEndDate} / yy:{" "}
                  {serviceEndYear}
                </p>
              </div>
              <div className="dateLabelStackedwIcon label">
                SERVICE END DATE{" "}
              </div>
            </div>
          </div>
          {(data.extended_details[0]?.comments ||
            (data.notes || data.notes?.notes)) && (
              <div
                style={{
                  width: "400px",
                  height: "20px",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "24px",
                  backgroundColor: "#f8f8f8",
                  position: "relative",
                  left: "20px",
                  top: "20px",
                }}
              >
                <div className="StatusActiveOpenEntitled-Container">
                  <div style={{ padding: "0 8px 0 0px" }}></div>
                  <div className="StatusActiveOpenEntitled-Label overline-regular">
                  Special Handling Instructions
                  </div>
                  <div>
                    <IconCheckCircle
                      filled
                      className="StatusActiveOpenEntitled-Check"
                    />
                  </div>
                  <div
                    style={{
                      height: "24px",
                      position: "relative",
                      top: "0px",
                      left: "113px",
                      backgroundColor: "#ffffff",
                      borderRadius: "24px",
                    }}
                  >
                    {isHideIcon && (
                      <IconPlusCircle
                        style={{ cursor: "pointer", color: "#027aae" }}
                        size={24}
                        onClick={openCommentBox}
                      />
                    )}

                    {!isHideIcon && (
                      <IconMinusCircle
                        style={{ cursor: "pointer", color: "#027aae" }}
                        size={24}
                        onClick={closeCommentBox}
                      />
                    )}
                  </div>
                </div>
                {showComments && (
                  <p
                    className="commentLabel label"
                    style={{
                      marginTop: "10px",
                      marginLeft: "2px",
                      backgroundColor: "#f8f8f8",
                      padding: "8px 16px",
                      boxShadow: "0px 0px 13px rgba(0,0,0,0.2)",
                      borderRadius: "8px",
                    }}
                  >
                    <span className="commentLabelText">{data.source_system?.value === "ITSM" &&
                      data.notes?.notes}
                    {data.source_system?.value === "K2" &&
                      data.extended_details[0]?.comments}</span>
                  </p>
                )}
              </div>
            )}
          {/* COMMENTS -WITHOUT COMMENTS */}
          {!data.extended_details[0]?.comments &&
            (!data.notes?.notes) && (
              <div
                style={{
                  width: "400px",
                  height: "20px",
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: "24px",
                  backgroundColor: "#f8f8f8",
                  position: "relative",
                  left: "20px",
                  top: "20px",
                }}
              >
                <div
                  className="StatusActiveOpenEntitled-Container"
                  style={{ backgroundColor: "#d3d3d380" }}
                >
                  <div style={{ padding: "0 8px 0 0px" }}></div>
                  <div className="StatusActiveOpenEntitled-Label overline-regular">
                  Special Handling Instructions
                  </div>
                  <div>
                    <IconCheckCircle filled className="StatusCLOSED-X" />
                  </div>
                  <div
                    style={{
                      height: "24px",
                      position: "relative",
                      top: "-1px",
                      left: "125px",
                      backgroundColor: "#ffffff",
                      borderRadius: "24px",
                    }}
                  >
                    <IconPlusCircle
                      disabled
                      style={{ cursor: "pointer", color: "#027aae" }}
                      size={24}
                    />
                  </div>
                </div>
                <p
                  className="commentLabel label"
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    padding: "8px 0px",
                  }}
                >
                 There are no instructions to show at this time
                </p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default EntitleBar;
