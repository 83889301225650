/* eslint-disable jsx-a11y/alt-text */
import React  from "react";
import Button from "@material-ui/core/Button";
import HP_Logo from "../Images/HP_logo.png";
import { sendGaEvent } from "../helper/analytics";

const LoginPage = (props) => {
  const { loggingIn } = props;
  const signInSuccess = () => {
    localStorage.clear();
    localStorage.setItem("loggedIn", "true");
    sendGaEvent('Log_in', 'User', 'User logged in');
    window.location.href = window._env_["REACT_APP_PALADIN_GET_TOKEN_API"];
  };
    return (
      <div className="overlayShow">
        <div className="loginHeaderClass">
          <span className="loginHeaderLogo">
            <img src={HP_Logo} style={{ width: 70, height: 70 }} />
          </span>
          <h2 className="loginHeaderText">Device Service & Supplies</h2>
        </div>
        <div className="contentShow">
          <div className="loginContent">
            <h3 className="loginHeaderH2">HP Employee</h3>
            <h6 className="loginHeaderH5">
              Use this login if you are an HP Employee
            </h6>

            <div>
              <Button
                className="loginButton"
                variant="contained"
                color="primary"
                onClick={signInSuccess}
              >
                Login
              </Button>
              {loggingIn && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default LoginPage;
