import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CommonDispatchContext,
  setSearchKeyword,
  CommonStateContext,
} from "../contexts/common";
import { useLocation } from "react-router-dom";
import HPlogo from "../Images/hp-logo-blue-rgb.svg";
import PersonIcon from "../Images/vnr-persona-icon.svg";
import { DevicesDispatchContext, getDevices } from "../contexts/devices";
import { sendGaEvent } from "../helper/analytics";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const isBroadcastMFE = window._env_["REACT_APP_ENABLE_BROADCAST_MFE"] === "true"? true : false ;
const Header = () => {
  let query = useQuery();
  const asseetIdSearch = "Search By: Asset ID, Host Name, or Serial Number";
  const contractIdSearch = "Search By: Contract Id, Customer Name, or OPD Id";
  const commonDispatch = useContext(CommonDispatchContext);
  const dispatch = useContext(DevicesDispatchContext);
  const { searchKeyword } = useContext(CommonStateContext);
  const history = useHistory();
  const isBulkOrderEnabled = window._env_["REACT_APP_ENABLE_BULK_ORDER_MFE_398"];
  const isTrustOrderEnabled = window._env_["REACT_APP_ENABLE_TRUST_ORDER_MFE_195"];
  const [region, setRegion] = useState(
    localStorage.getItem("region") ? localStorage.getItem("region") : "EMEA"
    );
  let searchKeyType = localStorage.getItem("searchType") ? localStorage.getItem("searchType") : 'Device';
  const [searchType, setSearchType] = useState(searchKeyType ? searchKeyType : 'Device');
  const [searchPlaceHolder, setSearchPlaceHolder] = useState(searchKeyType === 'Device' ? asseetIdSearch : contractIdSearch);

  const handleSelect = (event) => {
    const geoRegion =
      event.target.innerHTML === "Change Region"
        ? "EMEA"
        : event.target.innerHTML;
    setRegion(geoRegion);
    sendGaEvent(
      "Region_changed",
      "Device",
      `User changed region for: ${geoRegion}`
    );
    setSearchPlaceHolder(asseetIdSearch)
    localStorage.setItem("region", geoRegion);
  };

  const handleSearchType = (event) => {
    const searchType =
    event.target.innerHTML === "Device" ? "Device": event.target.innerHTML;
    if (searchType !== "Device") {
      setRegion("EMEA");
      localStorage.setItem("region", "EMEA");
    }
    setSearchType(searchType);
    setSearchPlaceHolder(event.target.innerHTML !== "Device" ? contractIdSearch : asseetIdSearch)
    localStorage.setItem("searchType", event.target.innerHTML);
    dispatch({
      type: "GET_DEVICES_SUCCESS",
      payload: {
        devices: [],
        totalCount: 0,
        region: '',
        searchType: '',
      }
    });
  };

  const handleSearchInput = (event) => {
    return setSearchKeyword(commonDispatch, event.target.value);
  };

  const handleSubmit = () => {
    const geoRegion = region === "Change Region" ? "EMEA" : region;
    getDevices(dispatch, searchKeyword, geoRegion, searchType);
    sendGaEvent(
      "Device_searched",
      "Device",
      `Device searched for: ${searchKeyword}`
    );
    if (history.location.pathname === "/deviceDetails" || history.location.pathname === '/bulkOrderDetail'
      || history.location.pathname === '/trustOrderDetail') {
      history.push("/search");
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleLogOutClick = () => {
    let path = `/`;
    localStorage.clear();
    sendGaEvent("Log_out", "User", "User logged out");
    history.push(path);
  };

  return (
    <section id="headerContainerWrapper">
      <div className="headerContainer">
        <div className="headerItem">
          <a href="/search">
            <div className="headerHPLogoNameContainer">
              <p>
                <img alt="HP" src={HPlogo} />
              </p>
              <div className="logoTitle">Device Service &#38; Supply Tool</div>
            </div>
          </a>
        </div>
        <div className="headerItem2">
          <div className="searchNavContainer">
              <div className="menu-area">
                <div className="flexDropDownContainerSelectRegion">
                { searchType && searchType === 'Device' &&
                <>
                  <div className="dropdownSelectRegion">
                    <a href="#">
                      <button id="dropbtnSelectRegion" disabled="true">Change Region</button>
                    </a>
                    <div className="dropdown-contentSelectRegion" onClick={handleSelect}>
                      <a href="#" >
                        AMS
                      </a>
                      <a href="#" >
                        APJ
                      </a>
                      <a href="#">
                        EMEA
                      </a>
                    </div>
                  </div>
                  </>
                }

              { searchType && searchType !== 'Device' &&
                <>
                  <div className="dropdownSelectRegion dropdownSelectType" style={{backgroundColor: "white"}}>
                    <a href="#">
                      <button id="dropbtnSelectRegionDisabled">Change Region</button>
                    </a>
                    <div className="dropdown-contentSelectRegion" >
                    </div>
                  </div>
                  </>
                }

                </div>
              </div>
              <div className="changeRegionReturnLabel">{region}</div>

            { ((isBulkOrderEnabled && isBulkOrderEnabled === "true") || (isTrustOrderEnabled && isTrustOrderEnabled === "true"))  &&
              <>
                <div className="menu-area">
                  <div className="flexDropDownContainerSelectRegion">
                    <div className="dropdownSelectRegion">
                      <a href="#">
                        <button id="dropbtnSelectRegion">Search Type</button>
                      </a>
                      <div className="dropdown-contentSelectRegion" onClick={handleSearchType}>
                        <a href="#" >
                          Device
                        </a>
                        {isBulkOrderEnabled && isBulkOrderEnabled === "true" && <a href="#" >
                          Bulk
                        </a>}
                        {isTrustOrderEnabled && isTrustOrderEnabled === "true" && <a href="#">
                          Trust
                        </a>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="changeRegionReturnLabel">{searchType}</div>
              </>
            }

            <div id="searchFieldContainer">
              <input
                placeholder={searchPlaceHolder}
                type="text"
                id="search"
                onChange={handleSearchInput}
                onKeyDown={handleKeyDown}
              ></input>
              <button className="goButton" type="button" onClick={handleSubmit}></button>
            </div>
          </div>
        </div>

       <div className="headerItem3">
          {isBroadcastMFE?
            <div className="userLoginButtonContainer">
              <div className="userFlexDropDownContainer">
                <div className="dropdown dropdownUserName">
                  <div className="dropbtn">
                    <button className="dropbtnUserName">
                      Broadcast
                    </button>
                  </div>
                  <div className="dropdown-content dropdown-contentUserName">
                    <a href="/broadcast">Broadcast</a>
                    <a href="/broadcastHistory">
                      Broadcast History
                    </a>
                  </div>
                </div>
              </div>
            </div>
            : ""
          }
          <div className="userLoginButtonContainer">
            <div className="userFlexDropDownContainer">
              <div className="dropdown dropdownUserName">
                <div className="dropbtn">
                  <button className="dropbtnUserName">
                    Hello &nbsp;{localStorage.getItem("user")}
                  </button>
                  <img alt="HP" src={PersonIcon} />
                </div>
                <div className="dropdown-content dropdown-contentUserName">
                  <a href="#">{localStorage.getItem("userEmail")}</a>
                  <a href="#" onClick={handleLogOutClick}>
                    Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{
        display: "flex",
        paddingLeft: "20%",
        JustifyContent: "center",
      }}>
      </div>
    </section>
  );
};

export default Header;
