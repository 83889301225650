import React from "react";
import { useHistory } from "react-router-dom";
import {
  Tabs,
  IconWrench,
  IconInk,
  IconPin,
  IconHardware,
  IconCalendarWrench,
  IconCalendarInk,
} from "@veneer/core";

const Navigation = (props) => {
  const mfeType = {
    0: "address",
    1: "supplies",
    2: "supplyHistory",
    3: "service",
    4: "serviceHistory",
    5: "software"
  };

  const data = props?.data;
  const type = props?.type;
  const isServiceMFE = window._env_["REACT_APP_ENABLE_SERVICE_MFE"];
  const isSupplyMFE = window._env_["REACT_APP_ENABLE_SUPPLY_MFE"];
  const isSoftwareMFE = window._env_["REACT_APP_ENABLE_SOFTWARE_MFE"];
  const history = useHistory();
  const tabId = Object.keys(mfeType).find(key => mfeType[key] === type);
  const [selectedTabIdDefault, setSelectedTabIdDefault] = React.useState(parseInt(tabId));
  const handleTabChange = (tabIndex) => {
    setSelectedTabIdDefault(tabIndex);
    loadMFE(mfeType[tabIndex]);
  };
  const loadMFE = (mfeComponentType) => {
    history.push(`/deviceDetails?id=${data.deviceDetails.id}&type=${mfeComponentType}`);
  };

  const tabList = [
    {
      id: 0,
      enable: 'true',
      label: (
        <div
          style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          lineHeight: "16px",
          }}
        >
          <div style={{ zIndex: "15" }}>
            <IconPin filled = {selectedTabIdDefault === 0} size={22} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            Addresses <br></br>&amp; Contacts
          </div>
        </div>
      ),
    },
    {
      id: 1,
      enable: isSupplyMFE,
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "16px",
            paddingLeft: "13px",
          }}
        >
          <div
            style={{
              zIndex: "15"}}>
            <IconInk filled={selectedTabIdDefault === 1} size={22} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
          Supply<br></br>Order
          </div>
        </div>
      ),
    },
    {
      id: 2,
      enable: isSupplyMFE,
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          <div style={{ zIndex: "15" }}>
            <IconCalendarInk filled ={selectedTabIdDefault === 2} size={25} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            Supply<br></br>Order History
          </div>
        </div>
      ),
    },
   {
      id: 3,
      enable: isServiceMFE,
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "16px",
            paddingLeft: "9.5px",
          }}
        >
          <div
            style={{
              zIndex: "15"}}>
            <IconWrench filled = {selectedTabIdDefault === 3} size={25} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
          Service<br></br>Request
          </div>
        </div>
      ),
    },
    {
      id: 4,
      enable: isServiceMFE,
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "16px",
            paddingLeft: "11px",
          }}
        >
          <div style={{ zIndex: "15" }}>
            <IconCalendarWrench filled = {selectedTabIdDefault === 4}
              size={26}
              style={{ color: "#027aae" }}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            Service<br></br>History
          </div>
        </div>
      ),
    },
    {
      id: 5,
      enable: isSoftwareMFE,
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            lineHeight: "16px",
          }}
        >
          <div style={{ zIndex: "15" }}>
            <IconHardware filled= {selectedTabIdDefault === 5} size={30} style={{ color: "#027aae" }} />
          </div>
          <div style={{ textAlign: "center" }}>
            Software <br></br>&amp; Accessories
          </div>
        </div>
      ),
    },
  ];

  const enableTabslist = tabList.filter ((tab) => {
    return tab.enable ==='true';
  })

  return (
    <div id="navContainerWrapper">
      <div className="navContainer">
        <Tabs
          mode="extended"
          onChangeTab={handleTabChange}
          selectedTabId={selectedTabIdDefault}
          tabs={enableTabslist}
          type="online"
        />
      </div>
    </div>
  );
};


export default Navigation;
