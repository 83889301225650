import React from "react";

const Footer = () => {
  return (
    <div id="footerContainerWrapper">
      <div>
        HP Device Service &#38; Supply
        Tool&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Contact HP DSS Support
      </div>
    </div>
  );
};

export default Footer;
