import React, { useContext } from "react";
import { DevicesStateContext } from "../contexts/devices";
import Loader from "react-loader-spinner";
import SessionTimeout from "../components/SessionTimeout";
import sessionContext from "../contexts/sessionContext";
import { sendGaEvent } from "../helper/analytics";

const Broadcast = (props) => {

    const {  isLoading, isLoaded } =
        useContext(DevicesStateContext);

    const handleLogOutClick = () => {
        let path = `/`;
        localStorage.clear();
        sendGaEvent("Log_out", "User", "User logged out");
        props.history.push(path);
    };

    if (isLoading) {
        return (
            <div className="alignLoader">
                <Loader
                    type="Oval"
                    color="#0171ad"
                    height={50}
                    width={50}
                    timeout={300000} //3 secs
                />
            </div>
        );
    }

    return (
        <sessionContext.Provider value={{ handleLogOutClick }}>
            <SessionTimeout handleLogOutClick={handleLogOutClick} />
            {!isLoaded && (
                <div style={{ marginTop: "6rem", alignItems: "center", width:"70%", marginLeft:"15%" }}>
                        <jupiter-sat-native
                            id="mfe-broadcast"
                            stack="dev"
                            context={JSON.stringify({
                                "page": "create",
                                "applicationName": "DSS",
                                "currentUserData": {email: localStorage.getItem("userEmail") === null ? "" : localStorage.getItem("userEmail")},
                                "token": localStorage.getItem("token") === null ? "" : localStorage.getItem("token"),
                            })}
                            hostName="DSS"
                            version="0.1.18"
                        />
                </div>

            )}
        </sessionContext.Provider>
    );
};

export default Broadcast;
