import TrustInfoCard from "../trustOrder/trustContractInfoCard";
import React, { useEffect, useState } from "react";
import { getContractInfo } from "../../services/deviceService";
import { useHistory, useLocation } from "react-router-dom";
import SessionTimeout from "../../components/SessionTimeout";
import sessionContext from "../../contexts/sessionContext";
import { sendGaEvent } from "../../helper/analytics";
import Loader from "react-loader-spinner";
import TrustSpecialHandlingCard from "../trustOrder/trustSpecialHandlingCard";
import BulkNavigation from "../../components/BulkNavigation";
import MFEComponent from "../../components/mfeComponent";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const BulkOrderDetails = (props) => {
    let query = useQuery();
    const history = useHistory();
    const [result, setResults] = useState();
    let type =( query.get("type") === 'bulk' || query.get("type") === 'supplies' ) ? 'supplies': 'supplyHistory';
    const [showLoading, setShowLoading] = useState(false);
    useEffect(() => {
        changeURL();
    }, []);

    const changeURL = async() => {
        setShowLoading(true);
        const data = await getContractInfo(query.get("id"), type);
        data.bulkMfes.forEach(
            mfe => mfe.context.orderType = localStorage.getItem("searchType") ? localStorage.getItem("searchType") : ""
        );
        data.mfes = data.bulkMfes;
        setResults(data);
        setShowLoading(false);
        history.push(`/bulkOrderDetail?id=${data.contractInfo.masterAgreementId}&type=${query.get("type")}`)
    }


    const handleLogOutClick = () => {
        let path = `/`;
        localStorage.clear();
        sendGaEvent("Log_out", "User", "User logged out");
        props.history.push(path);
    };

    return (
        <sessionContext.Provider value={{ handleLogOutClick }}>
            <SessionTimeout handleLogOutClick={handleLogOutClick} />
            {showLoading && (
                <>
                    <div className="alignLoader">
                        <Loader
                            type="Oval"
                            color="#0171ad"
                            height={50}
                            width={50}
                            timeout={300000} //3 secs
                        />
                    </div>
                </>
            )}
            {!showLoading && (
                <>
                    <div style={{ display: "flex", flexDirection: "column", position: "relative", left: "300" }}>
                        <div className="deviceDetailsWrapperContainer">
                            {result && <BulkNavigation data={result} type={type} />}
                            {result && <TrustSpecialHandlingCard data={result} />}
                            <div id="deviceDetailsContainer">
                                {result && <TrustInfoCard data={result} />}
                            </div>
                            {result && <MFEComponent data={result} type={type} />}
                        </div>
                    </div>
                </>
            )}
        </sessionContext.Provider>
    );
}

export default BulkOrderDetails;