/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "@jupiter/jupiter-engine/jupiter-engine-bundle-es5.min";
//import "@snr1kor/jupiter-engine-native/jupiter-engine-native-bundle-es5";
import { getDeviceDetails } from "../services/deviceService";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import DeviceCard from "../components/DeviceCard";
import Navigation from "../components/Navigation";
import EntitleBar from "../components/EntitleBar";
import MFEComponent from "../components/mfeComponent";
import SessionTimeout from "../components/SessionTimeout";
import sessionContext from "../contexts/sessionContext";
import { sendGaEvent } from "../helper/analytics";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function DeviceDetailsComponent(props) {
  let query = useQuery();
  const history = useHistory();
  let type = query.get("type");
  const [result, setResults] = useState();
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    changeURL();
  }, []);

  const changeURL = async() => {
    setShowLoading(true);
    const data = await getDeviceDetails(query.get("id"), query.get("asset"));
    setResults(data);
    setShowLoading(false);
    if(query.get("asset")) history.push(`/deviceDetails?id=${query.get("id")}&type=${data.mfes[0].name}`);
  }

  const handleLogOutClick = () => {
    let path = `/`;
    localStorage.clear();
    sendGaEvent("Log_out", "User", "User logged out");
    props.history.push(path);
  };

  return (
    <>
      <sessionContext.Provider value={{ handleLogOutClick }}>
        <SessionTimeout handleLogOutClick={handleLogOutClick} />
        {showLoading && (
          <>
            <div className="alignLoader">
              <Loader
                type="Oval"
                color="#0171ad"
                height={50}
                width={50}
                timeout={300000} //3 secs
              />
            </div>
          </>
        )}
        {!showLoading && (
          <>
            <div style={{display:"flex", flexDirection:"column",position:"relative",left:"300"}}>
              <div className="deviceDetailsWrapperContainer">
                {result && <Navigation data={result} type={type} />}
                {result && <EntitleBar data={result.deviceDetails} />}
                {result && (
                  <div id="deviceDetailsContainer">
                    <DeviceCard
                      key={result.deviceDetails.id}
                      data={result.deviceDetails}
                      type={type}
                    />
                  </div>
                )}
              </div>
            </div>
            {result && <MFEComponent data={result} type={type} />}
          </>
        )}
      </sessionContext.Provider>
    </>
  );
}

export default DeviceDetailsComponent;
