import ReactGA4 from 'react-ga4';

const Analytics = () => {
  try {
    if (window) {
      const { location: { pathname, search } } = window;
      ReactGA4.initialize(window._env_["REACT_APP_GA_TRACKING_ID"]);
      ReactGA4.send({ hitType: "pageview", page: pathname + search});
    }
    return;
  } catch (ex) {
    console.log("Google analytics not connected", ex);
    return;
  }
};

// Sending the events with event details
const sendGaEvent = (action, category, label, value = "") => {
  if (!!window.google_tag_manager) {
    let eventData = { action, category, label };
    if (value !== "") eventData = { ...eventData, value };
    ReactGA4.event(eventData);
  } else console.log("Google analytics not connected");
  return;
}

export { Analytics, sendGaEvent };