import React from "react";

const MFEComponent = (props) => {
  const data = props?.data;
  const type = props.type;
  const loadMfeConfiguration = data.mfes ? data.mfes.filter(mfe=>{
    if (mfe.name === type || (type ==='supplyHistory' && mfe.name === 'supplies') || (type ==='serviceHistory' && mfe.name === 'service')){
      return mfe;
    }
  }): [];
  if(loadMfeConfiguration && loadMfeConfiguration.length > 0) {
    loadMfeConfiguration[0].context.type = type;
  }
  return (
    <div className="statelliteContentContainer">
      <div id="contentContainerWrapper">
        {(type === "address") && loadMfeConfiguration.length > 0 && (
          <jupiter-sat-native
            id={loadMfeConfiguration[0].id}
            context={JSON.stringify(loadMfeConfiguration[0].context)}
            hostname={loadMfeConfiguration[0].hostname}
            stack={loadMfeConfiguration[0].stack}
          />
        )}
       {(type === "service") && loadMfeConfiguration.length > 0 && (
         <jupiter-sat-native
           id={loadMfeConfiguration[0].id}
           context={JSON.stringify(loadMfeConfiguration[0].context)}
           hostname={loadMfeConfiguration[0].hostname}
           stack={loadMfeConfiguration[0].stack}
         />
        )}
        {(type === "serviceHistory") && loadMfeConfiguration.length > 0 && (
         <jupiter-sat-native
           id={loadMfeConfiguration[0].id}
           context={JSON.stringify(loadMfeConfiguration[0].context)}
           hostname={loadMfeConfiguration[0].hostname}
           stack={loadMfeConfiguration[0].stack}
         />
        )}
        {(type === "supplies")&&  loadMfeConfiguration.length > 0  && (
          <jupiter-sat-native
            id={loadMfeConfiguration[0].id}
            context={JSON.stringify(loadMfeConfiguration[0].context)}
            hostname={loadMfeConfiguration[0].hostname}
            stack={loadMfeConfiguration[0].stack}
          />
        )}
         {(type === "supplyHistory" )&&  loadMfeConfiguration.length > 0  && (
          <jupiter-sat-native
            id={loadMfeConfiguration[0].id}
            context={JSON.stringify(loadMfeConfiguration[0].context)}
            hostname={loadMfeConfiguration[0].hostname}
            stack={loadMfeConfiguration[0].stack}
          />
        )}
        {type === "software" && loadMfeConfiguration.length > 0 && (
          <jupiter-sat-native
            id={loadMfeConfiguration[0].id}
            context={JSON.stringify(loadMfeConfiguration[0].context)}
            hostname={loadMfeConfiguration[0].hostname}
            stack={loadMfeConfiguration[0].stack}
          />
        )}
      </div>
     </div>
  );
};

export default MFEComponent;
