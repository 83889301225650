/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import "../App.css";
import { useHistory } from "react-router-dom";
import { getDeviceList } from "../services/deviceService";
import Loader from "react-loader-spinner";
import DeviceCard from "../components/DeviceCard";
import { Pagination } from "@veneer/core";
import { sendGaEvent } from "../helper/analytics";
import ContractCard from "../components/ContractCard";

function DeviceListComponent(props) {
  const componentType = "deviceList";
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showLoading, setShowLoading] = useState(false);
  const [result, setResults] = useState(props.result.devices);
  const [searchType, setSearchType] = useState(props.result.searchType);
  const totlPageCount = props.result.totalCount?.aggregate?.count;
  const history = useHistory();
  if (history.location.pathname === "/deviceDetails") {
    history.push("/search");
  }
  const handlePaginate = async (page) => {
    setShowLoading(true);
    setCurrentPage(page);
    const data = await getDeviceList(props.result.searchKeyword, page, '', '',props.result.searchType);
    setResults(props.result.searchType === "Device" ? data : data.contracts);
    setShowLoading(false);
    sendGaEvent(
      "Page_changed",
      "Device",
      `Device list page Changed for: ${page}`
    );
  };
  return (
    <div>
      {showLoading && (
        <>
          <div className="alignLoader">
            <Loader
              type="Oval"
              color="#0171ad"
              height={50}
              width={50}
              timeout={300000} //3 secs
            />
          </div>
        </>
      )}

       { result && (
        <div>
          <div id="searchPaginationBarContainerWrapper">
            <div id="searchPaginationBarContainer">
              {searchResulMessage(searchType)}
              <div className="pageItem">
                <Pagination
                  style={{ alignItems: "center", textAlign: "center" }}
                  currentPage={currentPage}
                  onPageChange={handlePaginate}
                  pageSize={4}
                  totalItems={totlPageCount}
                />
              </div>
            </div>
          </div>
          <div className="deviceSearchResultsContainerWrapper">
            <section>
              <div className="deviceContentContainerWrapper-SearchResults">
                {result.device &&  searchType === 'Device' && result.device.length > 0 && result.device[0].source_system?.value === "ORION" && (
                    orionWarningMessage(result, searchType)
                 )}
                 {result && searchType !== 'Device' && result.length > 0 && result[0].sourceSytem === "ORION" && (
                    orionWarningMessage()
                 )}
                {searchType === 'Device' && <div className="deviceCardContainer">
                  {result.device.map((data) => (
                      <DeviceCard
                        key={data.id}
                        data={data}
                        componentType={componentType}
                      />
                  ))}
                </div>}
                {searchType !== 'Device' && <div className="deviceCardContainer">
                  {result.map((data, i) => (
                      <ContractCard
                        key={i}
                        data={data}
                        searchType={searchType}
                        componentType={componentType}
                      />
                  ))}
                </div>}

              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );

  function searchResulMessage(searchType) {
    return <div className="searchItem">
      <div className="searchResultsLabel">Search Results: </div>
      {searchType === "Device" && <div className="searchResultsType">
        Serial Number, Asset Number , or Host Name:{" "}
      </div>}
      {searchType !== "Device" && <div className="searchResultsType">
        Contract No, Customer Name, or Opportunity Id:{" "}
      </div>}
      <div className="searchResultsTypeNumber">
        {props.result.searchKeyword}
      </div>
    </div>;
  }
}

const orionWarningMessage = () => {
  return <div>
    <p className="deviceDetailsOrionDisclaimer">Go To ORION Tool to View Device Details</p>
  </div>;
}
export default DeviceListComponent;

