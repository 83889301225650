import React, { useContext, useState } from "react";
import { Card } from "@veneer/core";
import DeviceListComponent from "../pages/deviceList";
import { DevicesStateContext } from "../contexts/devices";
import { CommonStateContext } from "../contexts/common";
import Loader from "react-loader-spinner";
import SessionTimeout from "../components/SessionTimeout";
import sessionContext from "../contexts/sessionContext";
import { sendGaEvent } from "../helper/analytics";

const Home = (props) => {
  let params = props.location?.search;

  if (params) {
    let searchParams = new URLSearchParams(params);
    if (searchParams.get("state") === "") props.history.push("/search");
    else if (searchParams.get("state")) {
      const state = JSON.parse(searchParams.get("state"));
      props.history.push(`/deviceDetails?asset=${state.asset}&id=${state.id}`);
    }
  }
  const isBroadcastMFE = window._env_["REACT_APP_ENABLE_BROADCAST_MFE"] === "true"? true : false ;
  const { devices, isLoading, isLoaded, totalCount, region, searchType } = useContext(DevicesStateContext);
  const { searchKeyword } = useContext(CommonStateContext);

  const handleLogOutClick = () => {
    let path = `/`;
    localStorage.clear();
    sendGaEvent("Log_out", "User", "User logged out");
    props.history.push(path);
  };

  if (isLoading) {
    return (
      <div className="alignLoader">
        <Loader
          type="Oval"
          color="#0171ad"
          height={50}
          width={50}
          timeout={300000} //3 secs
        />
      </div>
    );
  }


  return (
    <sessionContext.Provider value={{ handleLogOutClick }}>
      <SessionTimeout handleLogOutClick={handleLogOutClick} />
      {!isLoaded && (
        <>
       {isBroadcastMFE ? <div>
           <jupiter-sat-native
            id="mfe-broadcast-message"
            stack="dev"
            authMode="asset"
            context={JSON.stringify({
              "applicationName": "DSS",
              "token": localStorage.getItem("token") === null ? "" : localStorage.getItem("token"),
            })}
            hostName="DSS"
            version="0.1.12"
          />
        </div> : "" }

         {(!searchType || searchType === "Device") && <Card
            appearance="dropShadow"
            style={{
              width: "60%",
              padding: "60px 80px 80px",
              margin: "auto",
              position: "relative",
              top: "150px",
            }}
            content={
              <>
                <div
                  className="title-small--light"
                  style={{ marginBottom: "30px", textAlign: "center" }}
                >
                  Welcome to Device Services &#x26; Supplies

                </div>

                <div>
                  <p
                    className="subtitle-regular"
                    style={{ marginBottom: "50px", textAlign: "center" }}
                  >
                    Please be aware that this tool covers K2 and ITSM contractual
                    information sources.<br></br>You will be able to retrieve and
                    process any device as long as the contract is valid.
                  </p>

                  <p className="subtitle-regular" style={{ textAlign: "center" }}>
                    For Orion devices please go to the Orion Tool
                  </p>
                </div>
              </>
            }
          />}

        {searchType && searchType !== "Device" && <Card
            appearance="dropShadow"
            style={{
              width: "60%",
              padding: "60px 80px 80px",
              margin: "auto",
              position: "relative",
              top: "150px",
            }}
            content={
              <>
                <div
                  className="title-small--light"
                  style={{ marginBottom: "30px", textAlign: "center" }}
                >
                  Welcome to Device Services &#x26; Supplies

                </div>

                <div>
                  <p
                    className="subtitle-regular"
                    style={{ marginBottom: "50px", textAlign: "center" }}
                  >
                    DSS Search is invalid
                    <br></br>
                    Please verify that the Customer is an ITSM Customer and that the Contract ID, OPD ID or Customer Name are valid.
                    <br></br>
                    For Bulk Orders and Trust Orders for K2 based customers, please use VAS Search in Device Control Center (DCC).
                  </p>

                  <p className="subtitle-regular" style={{ textAlign: "center" }}>
                    {/* For Orion devices please go to the Orion Tool */}
                  </p>
                </div>
              </>
            }
          />}

        </>
      )}

       {isLoaded && searchType === "Device" && devices && devices?.device && devices?.device?.length > 0 && (
        <DeviceListComponent
          key={1}
          result={{ devices, searchKeyword, totalCount, searchType }}
        ></DeviceListComponent>
      )}
      {isLoaded && searchType !== "Device" && devices && devices?.length > 0 && (
        <DeviceListComponent
          key={1}
          result={{ devices, searchKeyword, totalCount, searchType}}
        ></DeviceListComponent>
      )}

      {isLoaded && (searchType === "Device" && devices.device.length === 0) && (
        <Card
          appearance="dropShadow"
          style={{
            width: "60%",
            padding: "60px 80px 80px",
            margin: "auto",
            position: "relative",
            top: "150px",
          }}
          content={
            <>
              <div
                className="title-small--light"
                style={{ marginBottom: "30px", textAlign: "center" }}
              >
                The device does not exist in {region} region, please search the device in another region.
              </div>

              <div>
                <p
                  className="subtitle-regular"
                  style={{ marginBottom: "50px", textAlign: "center" }}
                >
                  Please validate that the customer is a Trust Customer and exit
                  DSS to follow
                  <p
                    className="subtitle-regular"
                    style={{ textAlign: "center" }}
                  >
                    existing Trust order processes if necessary.
                  </p>
                </p>
              </div>
            </>
          }
        />
      )}

      {isLoaded && searchType !== "Device" && devices.length === 0 && ( <Card
            appearance="dropShadow"
            style={{
              width: "60%",
              padding: "60px 80px 80px",
              margin: "auto",
              position: "relative",
              top: "150px",
            }}
            content={
              <>
                <div
                  className="title-small--light"
                  style={{ marginBottom: "30px", textAlign: "center" }}
                >
                  Welcome to Device Services &#x26; Supplies

                </div>

                <div>
                  <p
                    className="subtitle-regular"
                    style={{ marginBottom: "50px", textAlign: "center" }}
                  >
                    DSS Search is invalid
                    <br></br>
                    Please verify that the Customer is an ITSM Customer and that the Contract ID, OPD ID or Customer Name are valid.
                    <br></br>
                    For Bulk Orders and Trust Orders for K2 based customers, please use VAS Search in Device Control Center (DCC).
                  </p>

                  <p className="subtitle-regular" style={{ textAlign: "center" }}>
                    {/* For Orion devices please go to the Orion Tool */}
                  </p>
                </div>
              </>
            }
          />
        
      )}
    </sessionContext.Provider>
  );
};

export default Home;
